<template>
  <div class="container">
    <nav-bar header-title="我的运动记录" :show-back="true" :header-back="appBack" />
    <div class="banner">
      <p class="count BebasNeueBold f72">{{ punchData.punchNum }} <span class="f24">节</span></p>
    </div>
    <div class="record">
      <p class="title f32 fw6">本场活动开始日期:{{ punchData.activityStartDate }}</p>
      <ul>
        <li class="record-item f28 row-between-center" v-for="item in punchData.punchRecordPage.records">
          <div class="row-start-center">
            <p class="p1">{{ item.punchDate }}</p>
            <p class="p2">{{ item.cityName }}</p>
          </div>
          <p class="p3 ellipsis">{{ item.courseName }}</p>
        </li>
      </ul>
      <div class="tip col-start-center" v-if="!punchData.punchRecordPage.records.length">
        <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/tip.png" alt="">
        <p class="f28">目前还没有上课记录哦 快去约课拿奖品吧</p>
        <div @click="appoint" class="button row-center-center">立即约课</div>
      </div>

    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, gotoAppPage } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import { getParam } from '@/lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      activityNo: '',
      punchData: {
        punchRecordPage: {
          records: []
        }
      },

    }
  },
  computed: {},
  async created() {
    this.activityNo = getParam().activityNo
    await this.$getAllInfo()
    initBack()

    this.getRecords()
  },
  methods: {
    appBack: newAppBack,
    getRecords() {
      this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true,
        duration: 0
      })
      this.$axios.post(`${this.baseURLApp}/differentIndustry/listPunchRecord`, {
        activityNo: this.activityNo,
        token: this.token,
        userId: this.userId,
        current: 1,
        size: 150
      }).then((res) => {
        this.punchData = res.data
        this.$toast.clear()
      })
    },
    appoint() {
      gotoAppPage(3)
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  background-color: rgba(226, 232, 249, 1);
  & > * { box-sizing: border-box; }
  .banner {
    width: 100%;
    height: 580px;
    background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/records-banner.png);
    background-size: 100% auto;
    position: relative;
    .count {
        width: 150px;
        position: absolute;
        left: 214px;
        top: 434px;
      }
  }
  .record {
    width: 100%;
    padding: 56px 32px;
    border-radius: 0 104px 0 0;
    background-color: #fff;

    color: #3C454E;
    .title {
      margin: 0 0 42px;
    }
    .record-item {
      margin: 0 0 48px;
      .p1 {
        margin: 0 54px 0 0;
      }
      .p3 {
        max-width: 300px;
      }
    }
  }
  .tip {
    width: 100%;
    margin: 100px 0 0;
    img {
      width: 284px;
    }
    p {
      margin: 40px 0 56px;
      color: #6C727A;
    }
    .button {
      width: 232px;
      height: 96px;
      background: #FFDE00;
      border-radius: 8px;
    }
  }
}
</style>
