<template>
  <div class="container">
    <nav-bar :header-title="headerTitle" :show-back="true" :header-back="appBack" />
    <div class="main col-start-center">
      <img class="banner" :src="activityInfo.mainVisualImg" alt />
      <!-- 打卡记录 -->
      <div class="count-box">
        <p class="count BebasNeueBold f72 row-center-center">
          {{ activityInfo.punchNum }}
          <span class="f24">节</span>
        </p>
        <div class="date-box col-center-center">
          <p class="f24 fw6">{{ activityInfo.activityStartDate }}</p>
          <p class="f24 fw6">{{ activityInfo.activityEndDate }}</p>
        </div>
        <div @click="$router.push(`records?activityNo=${activityNo}`)" class="record-box"></div>
        <div class="has-join row-center-center">
          <div class="heads row-start-center">
            <img :src="item" alt v-for="(item, index) in activityInfo.userHeadImgList" :key="index" />
          </div>
          <p class="fw6 f22">已有{{ activityInfo.participantNum }}人参加挑战</p>
        </div>
      </div>
      <!-- 规则、分享 -->
      <div class="share-box row-between-center">
        <img @click="ruleShow = true" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/button-rule.png" alt />
        <img @click="shareShow = true" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/button-share.png" alt />
      </div>
      <!-- <img class="title" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/aa-title1.png" alt /> -->
      <h2 v-if="activityInfo.giftActivityTitle" class="puhui fw5">{{ activityInfo.giftActivityTitle }}</h2>
      <div v-if="activityInfo.tips" class="tip-box row-center-center">
        <p class="p1 row-center-center f24 puhui">TIPS</p>
        <p class="p2 flex-auto row-start-center f24">{{ activityInfo.tips }}</p>
      </div>
      <div class="common-box" v-for="brand in activityInfo.brandGiftList">
        <div class="brand row-start-center">
          <img class="flex-none" :src="brand.brandLogo" alt />
          <div>
            <p class="p1 f32 fw6">{{ brand.brandActivityTitle }}</p>
            <p class="p2 f24">{{ brand.brandActivityScope }}</p>
          </div>
        </div>
        <div class="gift row-start-start" v-for="gift in brand.brandGiftInfoList">
          <div class="photo flex-none" :style="{ 'background-image': 'url(' + gift.brandGiftImg + ')' }"></div>
          <div class="desc">
            <p class="p1 f28 fw6">{{ gift.brandGiftName }}</p>
            <p class="p2 f22 ellipsis-2">{{ gift.brandGiftIntroduce }}</p>
            <div class="bar">
              <div class="progress" :style="`width: ${gift.remainStockProportion}%`"></div>
            </div>
            <p class="p3 f22">剩余{{ gift.remainStock }}份</p>
            <div class="row-start-center">
              <p class="p4 f24">{{ gift.getCondition }}</p>
              <img @click="getGift(gift)" class="button" :src="`https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/button-${buttonStatus(gift.getStatus)}.png`" alt />
            </div>
          </div>
        </div>
      </div>
      <img class="title" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/aa-title2.png" alt />
      <div class="other-box">
        <img v-for="item in activityInfo.brandWelfareList" @click="goBrand(item)" class="brand" :src="item.bannerImg" alt />
      </div>
      <img class="title" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/aa-title3.png" alt />
      <div class="other-box">
        <img v-for="item in activityInfo.otherFieryActivityList" @click="goOther(item.jumpUrl)" class="brand" :src="item.bannerImg" alt />
      </div>
    </div>
    <!--  分享配置  -->
    <common-share :value="shareShow" :shareParams="shareParams" @close="shareSuccess" @share-success="shareSuccess" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <!-- 需要绘制的dom 写这 -->
        <div class="canvas-dom">
          <img class="bg" :src="activityInfo.activitySharePoster" alt />
          <div class="content row-between-center">
            <div class="row-start-center">
              <img class="head flex-none" :src="userInfo.headImg" alt />
              <div class="name">
                <p class="p1 f28 fw6 ellipsis">{{ userInfo.nickName }}</p>
                <p class="p2 f20">
                  邀请您参与“3月春醒活动”，
                  <br />超鹿春醒季，健康一整年
                </p>
              </div>
            </div>
            <div class="qr col-center-center flex-none">
              <img :src="`data:image/png;base64,${userInfo.qrCode}`" alt />
            </div>
          </div>
        </div>
      </template>
    </common-share>
    <!-- 规则 -->
    <van-popup v-model="ruleShow">
      <div class="popup-box col-start-center">
        <div class="content f24" style="color: #666">
          <div class="scroll" v-html="activityInfo.activityRule"></div>
        </div>
        <van-icon name="close" size="30" color="#fff" @click="ruleShow = false" />
      </div>
    </van-popup>

    <!-- 兑换 -->
    <van-popup v-model="giftShow">
      <div class="popup-box col-start-center">
        <div class="gift-content col-start-center">
          <h3 v-if="giftGotten" class="f36">兑换成功！</h3>
          <h3 v-else class="f36">是否确认兑换该礼包？</h3>
          <p v-if="!giftGotten" class="p1 f24">每场活动仅能选择兑换一个礼包哦~</p>
          <img class="brand" :src="curGift.brandGiftImg" alt />
          <p class="p2 f24">{{ curGift.brandGiftName }}</p>
          <div class="tip f24" v-if="giftGotten">
            奖品已放入个人中心
          </div>
          <div class="buttons f32 row-between-center" v-else>
            <div @click="giftShow = false" class="button row-center-center">我再想想</div>
            <div @click="getGiftConfirm" class="button row-center-center primary fw5">确认兑换</div>
          </div>
        </div>
        <van-icon v-show="giftGotten" name="close" size="30" color="#fff" @click="giftShow = false" />
      </div>
    </van-popup>

    <!-- 前往app -->
    <van-popup v-model="appShow">
      <div class="popup-box col-start-center">
        <div class="gift-content col-start-center">
          <h3 class="f36">前往超鹿APP打开？</h3>
          <div class="buttons f32 row-between-center">
            <div @click="appShow = false" class="button row-center-center">取消</div>
            <div @click="goAppConfirm" class="button row-center-center primary fw5">立即前往</div>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 打开品牌 -->
    <van-popup v-model="brandShow">
      <div class="popup-box col-start-center">
        <div class="brand-content col-start-center">
          <img class="brand" :src="curBrand.bannerContent" alt />
          <!-- <img class="brand" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/has-da.png" alt /> -->

        </div>
        <van-icon  name="close" size="30" color="#fff" @click="brandShow = false" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, goMyVoucher, appType } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare'
import { getParam } from '@/lib/utils'

import wx from 'weixin-js-sdk'

export default {
  mixins: [userMixin],
  components: {
    navBar, commonShare
  },
  data() {
    return {
      shareShow: false,
      shareItem: ['minifriend', 'postermessage', 'save'],
      shareParams: {
        title: '3月春醒优惠， 不止7折！好春光，更要练一场',
        miniImage: '',
        path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/company-cooperation/index',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userInfo: { // 用户信息
        qrCode: '',
        nickName: '',
        headImg: '',
      },
      activityInfo: {
        brandGiftList: []
      },

      ruleShow: false,
      giftShow: false,
      activityNo: '',
      appTypeStr: '',

      curGift: {}, // 当前领取
      giftGotten: false, // 是否已领取

      appShow: false, // 是否打开app

      brandShow: false, // 品牌的弹窗
      curBrand: {}, // 当前品牌优惠信息

      headerTitle: ''
    }
  },
  computed: {
    // HAS_NOT_STARTED未开始
      // NOT_SATISFIED不可兑换
      // CONVERTIBLE,满足条件，可兑换
      // RECEIVED_OTHER,已领取其他礼包，不可领取
      // RECEIVED,已兑换且拿到礼包
      // STOCK_SHORTAGE,库存不足
      // HAS_ENDED已兑换且拿到礼包）,可用
    buttonStatus() {
      return function(status) {
        switch (status) {
          case 'CONVERTIBLE':
            return '1'
            break;
        case 'NOT_SATISFIED':
            return '7'
            break;
        case 'RECEIVED_OTHER':
            return '2'
            break;
        case 'RECEIVED':
            return '5'
            break;
        case 'STOCK_SHORTAGE':
            return '6'
            break;
        case 'HAS_ENDED':
            return '3'
            break;
          default:
            return '4'
            break;
        }
      }
    }
  },
  async created() {
    this.activityNo = getParam().activityNo
    this.appTypeStr = appType()
    await this.$getAllInfo()
    await this.getDetail()
    this.initQr()

    initBack()

    this.shareParams.path = `/pages/webView/index?webUrl=${window.location.origin}/#/company-cooperation/index&activityNo=${this.activityNo}`
    this.shareParams.miniImage = this.activityInfo.miniShareCover

    if (this.appTypeStr === 'mini') {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        }
      });
    }
  },
  methods: {
    appBack: newAppBack,
    initQr() {
      this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
        hasWx: true,
        token: this.token,
        userId: this.userId,
        width: 240,
        height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          source: 'yiye',
          activityNo: this.activityNo,
          webUrl: `${window.location.origin}/#/company-cooperation/index`,
        }),
      })
        .then((res) => {
          this.userInfo = res.data
        })
    },
    getDetail() {
      this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true,
        duration: 0
      })
      return this.$axios.post(`${this.baseURLApp}/differentIndustry/activityInfo`, {
        activityNo: this.activityNo,
        token: this.token,
        userId: this.userId,
      }).then((res) => {
        console.log('hhhh');
        this.activityInfo = res.data
        this.headerTitle = this.activityInfo.giftActivityTitle
        document.title = this.activityInfo.giftActivityTitle
        this.$toast.clear()
      })
    },

    shareSuccess() {
      this.shareShow = false
    },
    getGift(gift) {
      // HAS_NOT_STARTED未开始
      // NOT_SATISFIED不可兑换
      // CONVERTIBLE,满足条件，可兑换
      // RECEIVED_OTHER,已领取其他礼包，不可领取
      // RECEIVED,已兑换且拿到礼包
      // STOCK_SHORTAGE,库存不足
      // HAS_ENDED已兑换且拿到礼包）,可用
      if (gift.getStatus === 'RECEIVED_OTHER') {
        this.$toast({
          message: '您已领取其他礼包，下一场再来吧~',
          duration: 3000,
        })
        return
      }
      if (gift.getStatus === 'NOT_SATISFIED') {
        this.$toast({
          message: '快去上课积累节数来兑换吧',
          duration: 3000,
        })
        return
      }
      if (gift.getStatus === 'CONVERTIBLE') {
        this.giftGotten = false
        this.curGift = gift
        this.giftShow = true
      }

    },
    getGiftConfirm() {

      this.$toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      this.$axios.post(`${this.baseURLApp}/differentIndustry/receiveRedeem`, {
        activityNo: this.activityNo,
        token: this.token,
        userId: this.userId,
        associationResoucesId: this.curGift.associationResoucesId,
        brandGiftId: this.curGift.brandGiftId,
      }).then((res) => {
        this.$toast.clear()
        this.giftGotten = true
        this.getDetail()
      }).catch((res) => {
        this.$toast({
          message: res.data.msg,
          duration: 3000,
        })
      })

    },
    // 前往我的优惠券
    goMyVoucher() {
      goMyVoucher()
    },
    goBrand(item) {
      this.curBrand = item
      if (item.type === 1) {
        this.brandShow = true
        return
      }
      if (appType() === 'mini') {
        this.goApp()
        return
      }
      window.location.href = item.bannerContent
    },
    goOther(url) {
      if (appType() === 'mini') {
        this.goApp()
        return
      }
      window.location.href = url
    },
    goApp() {
      this.appShow = true
    },
    goAppConfirm() {
      window.location.href = 'http://a.app.qq.com/o/simple.jsp?pkgname=com.chaolu.app'
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  color: #3c454e;
  background: #e9ffb4;
  padding: 0 0 50px;
  * {
    box-sizing: border-box;
  }
  .main {
    .banner {
      width: 100%;
    }
    .count-box {
      width: 706px;
      height: 284px;
      margin-left: 20px;
      background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/has-da.png);
      background-size: cover;
      position: relative;
      .count {
        width: 150px;
        text-align: center;
        position: absolute;
        left: 204px;
        top: 110px;
        span {
          margin: 0 0 0 8px;
        }
      }
      .date-box {
        width: 140px;
        position: absolute;
        left: 514px;
        top: 84px;
        text-align: center;
        p {
          margin: 10px 0;
        }
      }
      .record-box {
        width: 100px;
        height: 80px;
        position: absolute;
        left: 364px;
        top: 100px;
      }
      .has-join {
        position: absolute;
        left: 32px;
        top: 180px;
      }
      .has-join {
        margin: 38px 0 0;
        .heads {
          margin: 0 12px 0 0;
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 4px solid #fff;
          margin: 0 0 0 -14px;
        }
      }
    }
    .share-box {
      width: 686px;
      margin: 22px 0 0;
      img {
        width: 332px;
      }
    }
    h2 {
      font-size: 56px;
      margin: 40px 0 36px;
      color: #242831;
    }
    .title {
      width: 544px;
      margin: 40px 0 36px;
    }
    .other-box {
      padding: 0 32px;
      .brand {
        width: 100%;
        display: block;
        margin: 0 0 24px;
      }
    }
    .tip-box {
      width: 686px;
      height: 60px;
      .p1 {
        width: 108px;
        height: 100%;
        background: #ff6e00;
        border-radius: 8px 0px 0px 8px;
        color: #fff;
      }
      .p2 {
        height: 100%;
        padding: 0 44px 0;
        background-color: #fff;
      }
    }
    .common-box {
      width: 100%;
      background: linear-gradient(135deg, #ffe96c 0%, #87fda9 100%);
      border-radius: 32px;
      padding: 40px 28px;
      margin: 32px 0 0;
      .brand {
        img {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          margin: 0 30px 0 0;
        }
        .p1 {

color: #232831;
        }
      }
      .gift {
        width: 100%;
        background: #ffffff;
        box-shadow: 6px 6px 4px 0px rgba(245, 255, 214, 0.35);
        border-radius: 16px;
        border: 4px solid rgba(71, 222, 158, 1);
        margin: 32px 0 0;
        padding: 40px 0 40px 24px;
        .photo {
          width: 252px;
          height: 210px;
          border-radius: 16px;
          background-size: cover;
          background-position: center;
          margin: 0 24px 0 0;
        }
        .desc {
          .bar {
            width: 284px;
            height: 16px;
            background: #eeeeee;
            border-radius: 9px;
            overflow: hidden;
            .progress {
              width: 108px;
              height: 16px;
              background: #ffde00;
              border-radius: 9px;
            }
          }
          .p2 {
            margin: 12px 0 42px;
            color: #6c727a;
            padding: 0 24px 0 0;
          }
          .p3 {
            color: #ff6e00;
            margin: 12px 0 8px;
          }
          .button {
            height: 100px;
          }
        }
      }
    }
  }
}
.canvas-dom {
  width: 654px;
  margin: auto;
  position: relative;
  .bg {
    width: 100%;
    display: block;
  }
  .content {
    width: 654px;
    position: absolute;
    bottom: 32px;
    left: 0;
    padding: 0 32px;
    box-sizing: border-box;
  }
  .head {
    width: 96px;
    height: 96px;
    border: 2px solid #ffffff;
    border-radius: 50%;
  }
  .name {
    color: rgba(51, 51, 51, 1);
    margin: 0 0 0 16px;
    .p1 {
      max-width: 250px;
    }
    .p2 {
      margin: 8px 0 0;
    }
  }
  .qr {
    img {
      width: 140px;
      border-radius: 10px;
    }
  }
}
.van-popup {
  background-color: transparent;
}
/deep/.popup-box {
  width: 654px;
  .content {
    width: 100%;
    height: 672px;
    background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/company-cooperation/rule-bg.png);
    background-size: contain;
  }
  i {
    margin: 32px 0 0;
  }
  .scroll {
    height: 526px;
    margin: 110px 0 0;
    padding: 24px 48px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .gift-content {
    width: 100%;
    padding: 64px 60px;
    background-color: #fff;
    border-radius: 24px;
    .p1 {
      margin: 16px 0 0;
      color: #000000;
      opacity: 0.4;
    }
    .gift {
      width: 398px;
    }
    .p2 {
      margin: 24px 0 0;
    }
    .tip {
      margin: 100px 0 0;
      color: rgba(255, 110, 0, 1);
    }
    .buttons {
      width: 100%;
      margin: 40px 0 0;
      .button {
        width: 232px;
        height: 96px;
        border-radius: 8px;
        border: 1px solid #dddddd;

        color: #3c454e;
        &.primary {
          border: none;

          background: #ffde00;
        }
      }
    }
    .brand {
      width: 100%;
      margin: 24px 0 0;
    }
  }
  .brand-content {
    width: 100%;
    padding: 0;
    background-color: transparent;
    border-radius: 24px;
    .brand {
      width: 100%;
    }
  }
}
</style>
